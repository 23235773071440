import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom"

import logo from '../images/logo.svg'
import menu from '../images/menu.svg'
// import arrow from '../images/arrow-right.svg'

function Header() {

  const [open, setOpen] = useState(false);

  let openClass = open ? "open" : "closed"

  useEffect(() => { 
    // document.body.style.overflow = "hidden"
    // document.body.parentElement.style.overflow = "hidden"
    // document.body.scroll = "no"
    // document.body.parentElement.style.overflow = open ? "hidden" : "auto"
    // document.getElementById("html")
  })

  return (

      <header className={`header ${openClass}`}>

        <div className="content">

          <div className="sector left">

            <Link to="/" className="logo_link">
              <img src={logo} className="logo" alt="logo" />
            </Link>
            
            <nav className={`nav ${openClass}`}>

              <ul className="list">
                <li className="item"><Link to="/filmmakers/" className="link">The Filmmakers</Link></li>
                <li className="item"><Link to="/laproducer/" className="link">LaProducer</Link></li>
                <li className="item"><Link to="/the_producers/" className="link">The Producers</Link></li>
                <li className="item"><Link to="/services/" className="link">Services</Link></li>
                <li className="item"><Link to="/contents/" className="link">Contents</Link></li>
                {/*<li className="item"><Link to="/contact/" className={`link ${openClass}`}>Contact Us</Link></li>*/}
              </ul>

            </nav>

          </div>

          <div className="sector right">

            {/*<Link to="/contact/" className="contact_button">
              <p className="text">Contact Us</p>
              <img src={arrow} className="arrow" alt="arrow" />
            </Link>*/}

            <button className="menu"><img src={menu} className="icon" alt="menu" onClick={() => setOpen(!open)} /></button>
            {/*<p>You clicked {open ? "true" : "false"} times</p>*/}

          </div>

        </div>

      </header>

  )
  
}

export default Header
