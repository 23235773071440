import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import advertising from "../../images/makers/krist.png"
import mifel from "../../images/makers/mifel.png"
import dentology from "../../images/makers/dentology.png"
import fashion from "../../images/makers/fashion.png"
import narciso from "../../images/makers/narciso.png"
import videoclip from "../../images/makers/videoclip.png"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Krist Alexander</h2>

            <div className="description">

              <p className="text">Director, screenwriter, and visual artist with a fresh, daring, and innovative look. After working in the film industry as AD, in both Mexico and Europe, he decided to make his way as director.</p>
              <p className="text">Krist stands out for his versatility and strict care for aesthetics, with a language full of sensitivity, a powerful visual style and a poetic narrative sense, these characteristics have led him to carry out different audiovisual projects, highlighting fiction, advertising, and video clips.</p>

            </div>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="639233629" src={advertising} className="maker_image" video="https://vimeo.com/639233629" onClick={this.imageClick} alt="639233629"/></div>
              <div className="maker_container"><img key="639239815" src={mifel} className="maker_image" video="https://vimeo.com/639239815" onClick={this.imageClick} alt="639239815"/></div>
              <div className="maker_container"><img key="639237694" src={dentology} className="maker_image" video="https://vimeo.com/639237694" onClick={this.imageClick} alt="639237694"/></div>
              <div className="maker_container"><img key="639238646" src={fashion} className="maker_image" video="https://vimeo.com/639238646" onClick={this.imageClick} alt="639238646"/></div>
              <div className="maker_container"><img key="639240611" src={narciso} className="maker_image" video="https://vimeo.com/639240611" onClick={this.imageClick} alt="639240611"/></div>
              <div className="maker_container"><img key="639236502" src={videoclip} className="maker_image" video="https://vimeo.com/639236502" onClick={this.imageClick} alt="639236502"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;