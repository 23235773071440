import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import pacifico from "../../images/makers/bruno/pacifico.avif"
import tostadas from "../../images/makers/bruno/tostadas.avif"
import yakimeshi from "../../images/makers/bruno/yakimeshi.avif"
import carro from "../../images/makers/bruno/carro.avif"
import escuela from "../../images/makers/bruno/escuela.avif"
import chilaquiles from "../../images/makers/bruno/chilaquiles.avif"
import alberca from "../../images/makers/bruno/alberca.avif"
import estudio from "../../images/makers/bruno/estudio.avif"
import rooftop from "../../images/makers/bruno/rooftop.avif"
import tamarind from "../../images/makers/bruno/tamarind.avif"
import stella from "../../images/makers/bruno/stella.avif"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Bruno Bengen</h2>

            <div className="description">

              <p className="text">Bruno is an argentinean director and photographer based in CDMX.</p>
              <p className="text">He has shot campaigns for whole Latin america and also for the US.</p>
              <p className="text">Bruno studied in many different places such as Buenos Aires, Tel Aviv and even in the International Center of Photography in New York City. </p>
              <p className="text">Bringing to the set all this cosmopolitan influences. </p>
              <p className="text">Though he has a wide range that goes from beauty to lifestyle and food, lately he has been focusing on the last one, going from minimalistic product shots to lifestyle oriented food advertisements.</p>
              <p className="text">He is always eager to approach new projects from the pre to the post. </p>

            </div>

            <div className="maker_wrapper">
              
              <div className="maker_container"><img key="792015497" src={pacifico} className="maker_image" video="https://vimeo.com/792015497" onClick={this.imageClick} alt="792015497"/></div>
              <div className="maker_container"><img key="792015693" src={tostadas} className="maker_image" video="https://vimeo.com/792015693" onClick={this.imageClick} alt="792015693"/></div>
              <div className="maker_container"><img key="792015962" src={yakimeshi} className="maker_image" video="https://vimeo.com/792015962" onClick={this.imageClick} alt="792015962"/></div>
              <div className="maker_container"><img key="792016312" src={carro} className="maker_image" video="https://vimeo.com/792016312" onClick={this.imageClick} alt="792016312"/></div>
              <div className="maker_container"><img key="792016362" src={escuela} className="maker_image" video="https://vimeo.com/792016362" onClick={this.imageClick} alt="792016362"/></div>
              <div className="maker_container"><img key="792016425" src={chilaquiles} className="maker_image" video="https://vimeo.com/792016425" onClick={this.imageClick} alt="792016425"/></div>
              <div className="maker_container"><img key="792016658" src={alberca} className="maker_image" video="https://vimeo.com/792016658" onClick={this.imageClick} alt="792016658"/></div>
              <div className="maker_container"><img key="792016891" src={estudio} className="maker_image" video="https://vimeo.com/792016891" onClick={this.imageClick} alt="792016891"/></div>
              <div className="maker_container"><img key="792017096" src={rooftop} className="maker_image" video="https://vimeo.com/792017096" onClick={this.imageClick} alt="792017096"/></div>
              <div className="maker_container"><img key="792017299" src={tamarind} className="maker_image" video="https://vimeo.com/792017299" onClick={this.imageClick} alt="792017299"/></div>
              <div className="maker_container"><img key="792017468" src={stella} className="maker_image" video="https://vimeo.com/792017468" onClick={this.imageClick} alt="792017468"/></div>

            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;