import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import alex0 from "../../images/makers/alex0.jpg"
import alex1 from "../../images/makers/alex1.jpg"
import alex2 from "../../images/makers/alex2.jpg"
import alex3 from "../../images/makers/alex3.jpg"
import alex4 from "../../images/makers/alex4.jpg"
import alex5 from "../../images/makers/alex5.jpg"
import alex6 from "../../images/makers/alex6.jpg"
import alex7 from "../../images/makers/alex7.jpg"
import alex8 from "../../images/makers/alex8.jpg"
import alex9 from "../../images/makers/alex9.jpg"
import alex10 from "../../images/makers/alex10.jpg"
import alex11 from "../../images/makers/alex11.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);

    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Alex Z. Reynaud</h2>

            <div className="description">

              <p className="text">Mexican filmmaker and photographer. His restlessness has led him to shoot around the world. His digital and avant-garde pieces have led him to participate at campaigns for important brands.</p>
              <p className="text">A cinematographer who understands the needs of a market that is constantly changing, especially when talking about corporate videos and social media where brands advertise to their targets.</p>

            </div>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="538716491" src={alex0} className="maker_image" video="https://vimeo.com/538716491" onClick={this.imageClick} alt="538716491"/></div>
              <div className="maker_container"><img key="315105073" src={alex11} className="maker_image" video="https://vimeo.com/315105073" onClick={this.imageClick} alt="315105073"/></div>
              <div className="maker_container"><img key="308633075" src={alex10} className="maker_image" video="https://vimeo.com/308633075" onClick={this.imageClick} alt="308633075"/></div>
              <div className="maker_container"><img key="305782424" src={alex8} className="maker_image" video="https://vimeo.com/305782424" onClick={this.imageClick} alt="305782424"/></div>
              <div className="maker_container"><img key="305780487" src={alex9} className="maker_image" video="https://vimeo.com/305780487" onClick={this.imageClick} alt="305780487"/></div>
              <div className="maker_container"><img key="170682421" src={alex1} className="maker_image" video="https://vimeo.com/170682421" onClick={this.imageClick} alt="170682421"/></div>
              <div className="maker_container"><img key="158979300" src={alex2} className="maker_image" video="https://vimeo.com/158979300" onClick={this.imageClick} alt="158979300"/></div>
              <div className="maker_container"><img key="158979298" src={alex3} className="maker_image" video="https://vimeo.com/158979298" onClick={this.imageClick} alt="158979298"/></div>
              <div className="maker_container"><img key="158979299" src={alex4} className="maker_image" video="https://vimeo.com/158979299" onClick={this.imageClick} alt="158979299"/></div>
              <div className="maker_container"><img key="183728491" src={alex5} className="maker_image" video="https://vimeo.com/183728491" onClick={this.imageClick} alt="183728491"/></div>
              <div className="maker_container"><img key="214923888" src={alex6} className="maker_image" video="https://vimeo.com/214923888" onClick={this.imageClick} alt="214923888"/></div>
              <div className="maker_container"><img key="279927217" src={alex7} className="maker_image" video="https://vimeo.com/279927217" onClick={this.imageClick} alt="279927217"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;