import React from 'react'

// import { Wrapper, Status } from "@googlemaps/react-wrapper";

import Map from '../services/GoogleMaps'

import Header from '../components/Header'
import Footer from '../components/Footer'

import blob_01 from '../images/blobs/blob_01.png'
import blob_02 from '../images/blobs/blob_02.png'
import blob_03 from '../images/blobs/blob_03.png'

import logo_01 from '../images/logos/logo_01.png'
import logo_02 from '../images/logos/logo_02.png'
import logo_03 from '../images/logos/logo_03.png'
import logo_04 from '../images/logos/logo_04.png'
import logo_05 from '../images/logos/logo_05.png'
import logo_06 from '../images/logos/logo_06.png'
import logo_07 from '../images/logos/logo_07.png'
import logo_08 from '../images/logos/logo_08.png'
import logo_09 from '../images/logos/logo_09.png'
import logo_10 from '../images/logos/logo_10.png'
import logo_11 from '../images/logos/logo_11.png'
import logo_12 from '../images/logos/logo_12.png'
import logo_13 from '../images/logos/logo_13.png'
import logo_14 from '../images/logos/logo_14.png'
import logo_15 from '../images/logos/logo_15.png'
import logo_16 from '../images/logos/logo_16.png'
import logo_17 from '../images/logos/logo_17.png'
import logo_18 from '../images/logos/logo_18.png'
import logo_19 from '../images/logos/logo_19.png'
import logo_20 from '../images/logos/logo_20.png'
import logo_21 from '../images/logos/logo_21.png'
import logo_22 from '../images/logos/logo_22.png'

import logo_24 from '../images/logos/logo_24.png'
import logo_25 from '../images/logos/logo_25.png'
import logo_26 from '../images/logos/logo_26.png'
import logo_27 from '../images/logos/logo_27.png'
import logo_28 from '../images/logos/logo_28.png'
import logo_29 from '../images/logos/logo_29.png'
import logo_30 from '../images/logos/logo_30.png'
import logo_31 from '../images/logos/logo_31.png'
import logo_32 from '../images/logos/logo_32.png'
import logo_33 from '../images/logos/logo_33.png'
import logo_34 from '../images/logos/logo_34.png'
import logo_35 from '../images/logos/logo_35.png'
import logo_36 from '../images/logos/logo_36.png'
import logo_37 from '../images/logos/logo_37.png'
import logo_38 from '../images/logos/logo_38.png'
import logo_39 from '../images/logos/logo_39.png'
import logo_40 from '../images/logos/logo_40.png'
import logo_41 from '../images/logos/logo_41.png'

class Home extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      iteration: true,
    }

    this.interval_id = null

    this.iteration = true
    this.iteration_02 = true

    this.bgInterval = this.bgInterval.bind(this)

  }

  bgInterval() {

    console.log("interval")

    var iteration = this.iteration
    let slider = document.getElementById('slider_top')

    if (iteration) {
      
      slider.classList.remove("animation")
      slider.classList.add("animation")

    } else {

      var firstChild = slider.firstChild
      slider.removeChild(firstChild)
      slider.appendChild(firstChild)

      slider.classList.remove("animation")

    }

    this.iteration = !iteration



    var iteration_02 = this.iteration_02
    let slider_02 = document.getElementById('slider_bottom')

    if (iteration_02) {
      
      slider_02.classList.remove("animation")
      slider_02.classList.add("animation")

    } else {

      var child = slider_02.lastChild
      slider_02.removeChild(child)
      slider_02.prepend(child)

      slider_02.classList.remove("animation")

    }

    this.iteration_02 = !iteration_02

  }

  linearInterval() {

    let slider = document.getElementById('slider_top')

    // var iteration_02 = this.iteration_02
    let slider_02 = document.getElementById('slider_bottom')

    slider.classList.add("animation")
    slider_02.classList.add("animation")

    setTimeout(() => {

      slider.classList.remove("animation")
      var firstChild = slider.firstChild
      slider.removeChild(firstChild)
      slider.appendChild(firstChild)

      var child = slider_02.lastChild
      slider_02.removeChild(child)
      slider_02.prepend(child)

      slider_02.classList.remove("animation")

    }, 1000)

  }

  componentDidMount() {

    this.interval_id = setInterval(this.linearInterval, 1030)

  }

  componentWillUnmount() {
     clearInterval(this.interval_id)
  }

  render () {
    
    return (

      <div className="container home">

        <img src={blob_01} className="blob_01" alt="decoration"/>
        <img src={blob_02} className="blob_02" alt="decoration"/>
        <img src={blob_03} className="blob_03" alt="decoration"/>

        <Header />
        
        <main className="main">

          <div className="content">
            
            <div className="landing">
              <p className="intro">Make your <span className="ideas">ideas<br/></span><span className="into">turn into</span><br/><span className="gold">gold</span></p>
              <p className="paragraph">WE ARE AN AUDIOVISUAL ADVERTISING & CONTENT PRODUCTION COMPANY BASED IN MEXICO CITY SINCE 2006</p>
              <p className="paragraph solutions">WE OFFER VIDEO SOLUTIONS CONNECTING BRANDS AND PEOPLE THROUGH CINEMATIC STORYTELLING.</p>
            </div>

            <div className="clients">

              <h3 className="title">CLIENTS</h3>

              <div className="slider first">

                <div className="slider_list" id="slider_top">

                  <div className="slider_wrapper"><img src={logo_01} className="slider_image" alt="logo_51" /></div>
                  <div className="slider_wrapper"><img src={logo_03} className="slider_image" alt="logo_53" /></div>
                  <div className="slider_wrapper"><img src={logo_04} className="slider_image" alt="logo_54" /></div>
                  <div className="slider_wrapper"><img src={logo_05} className="slider_image" alt="logo_55" /></div>
                  <div className="slider_wrapper"><img src={logo_06} className="slider_image" alt="logo_56" /></div>
                  <div className="slider_wrapper"><img src={logo_07} className="slider_image" alt="logo_57" /></div>
                  <div className="slider_wrapper"><img src={logo_08} className="slider_image" alt="logo_58" /></div>
                  <div className="slider_wrapper"><img src={logo_09} className="slider_image" alt="logo_59" /></div>
                  <div className="slider_wrapper"><img src={logo_10} className="slider_image" alt="logo_60" /></div>
                  <div className="slider_wrapper"><img src={logo_11} className="slider_image" alt="logo_61" /></div>
                  <div className="slider_wrapper"><img src={logo_12} className="slider_image" alt="logo_62" /></div>
                  <div className="slider_wrapper"><img src={logo_13} className="slider_image" alt="logo_63" /></div>
                  <div className="slider_wrapper"><img src={logo_14} className="slider_image" alt="logo_64" /></div>
                  <div className="slider_wrapper"><img src={logo_15} className="slider_image" alt="logo_65" /></div>
                  <div className="slider_wrapper"><img src={logo_16} className="slider_image" alt="logo_66" /></div>
                  <div className="slider_wrapper"><img src={logo_17} className="slider_image" alt="logo_67" /></div>
                  <div className="slider_wrapper"><img src={logo_18} className="slider_image" alt="logo_68" /></div>
                  <div className="slider_wrapper"><img src={logo_19} className="slider_image" alt="logo_69" /></div>
                  <div className="slider_wrapper"><img src={logo_20} className="slider_image" alt="logo_70" /></div>
                  
                  <div className="slider_wrapper"><img src={logo_41} className="slider_image" alt="logo_91" /></div>

                </div>

              </div>

              <div className="slider second">

                <div className="slider_list" id="slider_bottom">

                  <div className="slider_wrapper"><img src={logo_21} className="slider_image" alt="logo_71" /></div>
                  <div className="slider_wrapper"><img src={logo_22} className="slider_image" alt="logo_72" /></div>
                  <div className="slider_wrapper"><img src={logo_24} className="slider_image" alt="logo_74" /></div>
                  <div className="slider_wrapper"><img src={logo_25} className="slider_image" alt="logo_74" /></div>
                  <div className="slider_wrapper"><img src={logo_26} className="slider_image" alt="logo_76" /></div>
                  <div className="slider_wrapper"><img src={logo_27} className="slider_image" alt="logo_77" /></div>
                  <div className="slider_wrapper"><img src={logo_28} className="slider_image" alt="logo_77" /></div>
                  <div className="slider_wrapper"><img src={logo_29} className="slider_image" alt="logo_79" /></div>
                  <div className="slider_wrapper"><img src={logo_30} className="slider_image" alt="logo_80" /></div>
                  <div className="slider_wrapper"><img src={logo_31} className="slider_image" alt="logo_81" /></div>
                  <div className="slider_wrapper"><img src={logo_32} className="slider_image" alt="logo_82" /></div>
                  <div className="slider_wrapper"><img src={logo_33} className="slider_image" alt="logo_83" /></div>
                  <div className="slider_wrapper"><img src={logo_34} className="slider_image" alt="logo_84" /></div>
                  <div className="slider_wrapper"><img src={logo_35} className="slider_image" alt="logo_85" /></div>
                  <div className="slider_wrapper"><img src={logo_36} className="slider_image" alt="logo_86" /></div>
                  <div className="slider_wrapper"><img src={logo_37} className="slider_image" alt="logo_87" /></div>
                  <div className="slider_wrapper"><img src={logo_38} className="slider_image" alt="logo_88" /></div>
                  <div className="slider_wrapper"><img src={logo_39} className="slider_image" alt="logo_89" /></div>
                  <div className="slider_wrapper"><img src={logo_40} className="slider_image" alt="logo_90" /></div>
                  
                  <div className="slider_wrapper"><img src={logo_02} className="slider_image" alt="logo_52" /></div>

                </div>

              </div>

            </div>

            <div className="work">

              <p className="together">LET'S WORK TOGETHER</p>
              <p className="contact">CONTACT US!</p>

              <div className="map">
                <Map className="google_maps" />
              </div>

            </div>

          </div>
          
        </main>

        <Footer />
        
      </div>

    )

  }

}

export default Home
