
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'

import producers from '../images/producers.png'
import blob from '../images/blobs/blob_04.png'

function Producers() {

  return (

    <div className="container producers">

      <img src={blob} className="blob" alt="decoration" />

      <Header />
      
      <main className="main">

        <Banner title={<h2 className="title">the producers</h2>} />

        <div className="content">

          <div className="wrapper">
            
            <p className="paragraph">
              THE EXECUTIVE PRODUCERS OVERSEE CARRYING OUT EACH PROJECT TO ENSURE THAT THE CREATIVE AND EDITORIAL GOALS ARE MET. THEY ARE IN CHARGE OF TAKING THE BRIEFS, BUDGETING, PRE-PRODUCING, PRODUCING AND POST-PRODUCING THE MATERIALS TO DELIVER THE BEST QUALITY TO OUR CLIENTS. THERE IS ALWAYS SOMEONE AVAILABLE TO HAPPILY SUITE YOUR NEEDS.
            </p>
            
          </div>

          <img src={producers} className="image" alt="LaProducer" />

        </div>
        
      </main>

      <Footer />
      
    </div>

  )

}

export default Producers;
