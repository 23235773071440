
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'

function Contact() {

  return (

    <div className="container contact">

      <Header />
      
      <main className="main">

        <Banner title={<h2 className="title">contact&ensp;<br/>us</h2>} />

        <div className="content">
          <p className="holder">Aqui va el contenido</p>
        </div>
        
      </main>

      <Footer />
      
    </div>

  )

}

export default Contact;
