import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import './scss/index.scss';

import Home from './pages/Home'
import Filmmakers from './pages/Filmmakers'
import LaProducer from './pages/LaProducer'
import Producers from './pages/Producers'
import Services from './pages/Services'
import Contents from './pages/Contents'
import Contact from './pages/Contact'

import AlexReynaud from './pages/makers/AlexReynaud'
import DavidAlcalde from './pages/makers/DavidAlcalde'
import KristAlexander from './pages/makers/KristAlexander'
import LeoGanora from './pages/makers/LeoGanora'
import LosHomies from './pages/makers/LosHomies'
import MarioZavala from './pages/makers/MarioZavala'
import MartinPimentel from './pages/makers/MartinPimentel'
import RafaLara from './pages/makers/RafaLara'
import RobyChiralt from './pages/makers/RobyChiralt'
import RodrigoFiallega from './pages/makers/RodrigoFiallega'
import Nadatalvez from './pages/makers/Nadatalvez'
import Liberta from './pages/makers/Liberta'
import BrunoBengen from './pages/makers/BrunoBengen'
import ArturoFincowsky from './pages/makers/ArturoFincowsky'
import VanesaManzano from './pages/makers/VanesaManzano'

import reportWebVitals from './reportWebVitals';

function Paths() {

  return (

    <Router>
      <Routes>
          
        <Route exact path="/" element={<Home />} />
        <Route exact path="filmmakers" element={<Filmmakers />} />
        <Route exact path="laproducer" element={<LaProducer />} />
        <Route exact path="the_producers" element={<Producers />} />
        <Route exact path="services" element={<Services />} />
        <Route exact path="contents" element={<Contents />} />
        <Route exact path="contact" element={<Contact />} />

        <Route exact path="filmmakers/alex-reynaud/" element={<AlexReynaud />} />
        <Route exact path="filmmakers/david-alcalde/" element={<DavidAlcalde />} />
        <Route exact path="filmmakers/krist-alexander/" element={<KristAlexander />} />
        <Route exact path="filmmakers/leo-ganora/" element={<LeoGanora />} />
        <Route exact path="filmmakers/los-homies/" element={<LosHomies />} />
        <Route exact path="filmmakers/mario-zavala/" element={<MarioZavala />} />
        <Route exact path="filmmakers/martin-pimentel/" element={<MartinPimentel />} />
        <Route exact path="filmmakers/rafa-lara/" element={<RafaLara />} />
        <Route exact path="filmmakers/rodrigo-fiallega/" element={<RodrigoFiallega />} />
        <Route exact path="filmmakers/roby-chiralt/" element={<RobyChiralt />} />
        <Route exact path="filmmakers/nadatalvez/" element={<Nadatalvez />} />
        <Route exact path="filmmakers/liberta/" element={<Liberta />} />
        <Route exact path="filmmakers/bruno-bengen/" element={<BrunoBengen />} />
        <Route exact path="filmmakers/arturo-fincowsky/" element={<ArturoFincowsky />} />

        <Route exact path="filmmakers/vanesa-manzano/" element={<VanesaManzano />} />

      </Routes>
    </Router>

  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Paths />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
