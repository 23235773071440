import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import sony from "../../images/makers/fiallega/sony.jpg"
import doritos from "../../images/makers/fiallega/doritos.jpg"
import lala from "../../images/makers/fiallega/lala.jpg"
import escac from "../../images/makers/fiallega/escac.jpg"
import aspirina from "../../images/makers/fiallega/aspirina.jpg"
import pedigree from "../../images/makers/fiallega/pedigree.jpg"
import att from "../../images/makers/fiallega/att.jpg"
import budlight from "../../images/makers/fiallega/budlight.jpg"
import seat from "../../images/makers/fiallega/seat.jpg"
import donas from "../../images/makers/fiallega/donas.jpg"
import bimbo from "../../images/makers/fiallega/bimbo.jpg"
import nike from "../../images/makers/fiallega/nike.jpg"
import toyota from "../../images/makers/fiallega/toyota.jpg"
import dove from "../../images/makers/fiallega/dove.jpg"
import rockaleta from "../../images/makers/fiallega/rockaleta.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Rodrigo Fiallega</h2>

            <div className="description">

              <p className="text">(IMDb) Screenwriter / Director / Producer.  Rodrigo studied Philosophy at the Universidad Nacional Autónoma de Mexico and Audiovisual Communication at the Universidad Iberoamericana. After beginning his career in advertising cinema as an assistant director and editor at Alejandro González Iñárritu's production company (Z Film) and working with directors such as Antonio Urrutia or Armando Bo, he followed his own path, beginning to experiment with photography, video and animation, as well as working with other filmmakers on videos and documentaries while continuing to work in advertising. He then decides to go to Barcelona, where he studied a Master in Interactive Audiovisual Communication (Universitat de Barcelona) and a PhD in Film Theory and Analysis (Universitat Pompeu Fabra). Living in Europe, he develops a career as a visual effects artist working in studios in London, Barcelona, Brussels and Copenhagen and in films such as Mission Impossible: Ghost Protocol (2011), Olympus has fallen (2013), Unbroken (2014), The Expendables 3 ( 2014), Ragnarok (2014) and Km31: No Return (2015)</p>
              <p className="text">Some of his projects as a director (short films, animations, video clips, etc.) have been selected at numerous festivals around the world such as IndieLisboa (Portugal), Animadrid (Spain), Cinematou (Switzerland), I've seen Films (Italy) , Cinérail (France), Ottawa International Animation Festival (Canada), Morelia International Film Festival (Mexico), Cannes Short Film Corner, etc.</p>
              <p className="text">In 2015 he returned to Mexico to film "Ricochet", his First Feature, which premiered as part of the Official Selection of the Rome Film Festival 2020 and its national premiere at the Morelia International Film Festival 2020 where he won the award for “best actor”. Among others, it has also been selected at festivals such as D'A Barcelona or the Shanghai International Film Festival. At the Zsigmond Vilmos film festival in Hungary, it recently won the award for best feature film.</p>
              <p className="text">The commissioned film “El exorcismo de Carmen Farías”, starring Camila Sodi, had its commercial premiere in 2021. It was the fifth highest-grossing Mexican film of that year, which earned him a nomination for Best Director at the CANACINE awards in this year.</p>
              <p className="text">He currently lives in Mexico where he works as a commercial director while developing his next film projects.</p>

            </div>

            <div className="maker_wrapper">
            
              <div className="maker_container"><img key="819984812" src={sony} className="maker_image" video="https://vimeo.com/819984812" onClick={this.imageClick} alt="819984812"/></div>
              <div className="maker_container"><img key="819984242" src={doritos} className="maker_image" video="https://vimeo.com/819984242" onClick={this.imageClick} alt="819984242"/></div>
              <div className="maker_container"><img key="819984490" src={lala} className="maker_image" video="https://vimeo.com/819984490" onClick={this.imageClick} alt="819984490"/></div>
              <div className="maker_container"><img key="819984422" src={escac} className="maker_image" video="https://vimeo.com/819984422" onClick={this.imageClick} alt="819984422"/></div>
              <div className="maker_container"><img key="819983695" src={aspirina} className="maker_image" video="https://vimeo.com/819983695" onClick={this.imageClick} alt="819983695"/></div>
              <div className="maker_container"><img key="819984581" src={pedigree} className="maker_image" video="https://vimeo.com/819984581" onClick={this.imageClick} alt="819984581"/></div>
              <div className="maker_container"><img key="819983769" src={att} className="maker_image" video="https://vimeo.com/819983769" onClick={this.imageClick} alt="819983769"/></div>
              <div className="maker_container"><img key="819984033" src={budlight} className="maker_image" video="https://vimeo.com/819984033" onClick={this.imageClick} alt="819984033"/></div>
              <div className="maker_container"><img key="819984789" src={seat} className="maker_image" video="https://vimeo.com/819984789" onClick={this.imageClick} alt="819984789"/></div>
              <div className="maker_container"><img key="819983818" src={donas} className="maker_image" video="https://vimeo.com/819983818" onClick={this.imageClick} alt="819983818"/></div>
              <div className="maker_container"><img key="819983956" src={bimbo} className="maker_image" video="https://vimeo.com/819983956" onClick={this.imageClick} alt="819983956"/></div>
              <div className="maker_container"><img key="819984534" src={nike} className="maker_image" video="https://vimeo.com/819984534" onClick={this.imageClick} alt="819984534"/></div>
              <div className="maker_container"><img key="819984935" src={toyota} className="maker_image" video="https://vimeo.com/819984935" onClick={this.imageClick} alt="819984935"/></div>
              <div className="maker_container"><img key="819984366" src={dove} className="maker_image" video="https://vimeo.com/819984366" onClick={this.imageClick} alt="819984366"/></div>
              <div className="maker_container"><img key="819984707" src={rockaleta} className="maker_image" video="https://vimeo.com/819984707" onClick={this.imageClick} alt="819984707"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;