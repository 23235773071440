import React from 'react';
import minimize from "../images/minimize.svg"

class Video extends React.Component {

	constructor(props) {
	    super(props)
	    this.menuClick = this.menuClick.bind(this)
	}

	menuClick(event) {
		event.preventDefault();
		document.getElementById('video').classList.toggle("hidden");
		document.getElementById('iframe').setAttribute("src", "")
	}

	render () {

		let video_url = "https://player.vimeo.com/video/" + this.props.video

		return (

			<div className="video hidden" id="video">


				<div className="video_container">
					<div className="video_close"><img src={minimize} className="video_icon" onClick={this.menuClick} alt="thumbnail" /></div>
					<iframe className="video_frame" id="iframe" src={video_url} title="video" frameBorder="0" width="640" height="360" allow="autoplay; fullscreen" allowFullScreen></iframe>
				</div>

			</div>

		);
	}
}

export default Video;
