import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import animalplanet_lost from "../../images/makers/fincowsky/animalplanet_lost.jpg"
import audi_lifestyle from "../../images/makers/fincowsky/audi_lifestyle.jpg"
import bimbo_especialidades from "../../images/makers/fincowsky/bimbo_especialidades.jpg"
import boost_rosalia from "../../images/makers/fincowsky/boost_rosalia.jpg"
import claudia_booties from "../../images/makers/fincowsky/claudia_booties.jpg"
import cruzroja_manos from "../../images/makers/fincowsky/cruzroja_manos.jpg"
import nesquik_barcelona from "../../images/makers/fincowsky/nesquik_barcelona.jpg"
import olympic_lifestyle from "../../images/makers/fincowsky/olympic_lifestyle.jpg"
import boost_pesopluma from "../../images/makers/fincowsky/boost_pesopluma.jpg"
import smart_cine from "../../images/makers/fincowsky/smart_cine.jpg"
import smart_malinche from "../../images/makers/fincowsky/smart_malinche.jpg"
import smart_novela from "../../images/makers/fincowsky/smart_novela.jpg"
import smart_organillero from "../../images/makers/fincowsky/smart_organillero.jpg"
import under_armour from "../../images/makers/fincowsky/under_armour.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Arturo Fincowsky</h2>

            <div className="description">

              <p className="text">With over 25 years working in the advertising and audiovisual production industry, Arturo Fincowsky is one of the most experienced and successful directors and producers in Mexico's advertising industry. </p>
              <p className="text">With a degree in Advertising and experience working with some of the largest agencies and clients in the region, Arturo has collaborated on successful advertising campaigns and innovative audiovisual projects in many industries. </p>
              <p className="text">His cinematic direction has led him to execute highly profitable campaigns for his clients, perfecting his ability to create concepts and implement narratives that resonate deeply with the audience.</p>

            </div>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="829978314" src={under_armour} className="maker_image" video="https://vimeo.com/829978314" onClick={this.imageClick} alt="829978314"/></div>
              <div className="maker_container"><img key="829972061" src={boost_pesopluma} className="maker_image" video="https://vimeo.com/829972061" onClick={this.imageClick} alt="829972061"/></div>
              <div className="maker_container"><img key="829971163" src={audi_lifestyle} className="maker_image" video="https://vimeo.com/829971163" onClick={this.imageClick} alt="829971163"/></div>
              <div className="maker_container"><img key="829973811" src={cruzroja_manos} className="maker_image" video="https://vimeo.com/829973811" onClick={this.imageClick} alt="829973811"/></div>
              <div className="maker_container"><img key="829975229" src={olympic_lifestyle} className="maker_image" video="https://vimeo.com/829975229" onClick={this.imageClick} alt="829975229"/></div>
              <div className="maker_container"><img key="829972779" src={boost_rosalia} className="maker_image" video="https://vimeo.com/829972779" onClick={this.imageClick} alt="829972779"/></div>
              <div className="maker_container"><img key="829973385" src={claudia_booties} className="maker_image" video="https://vimeo.com/829973385" onClick={this.imageClick} alt="829973385"/></div>
              <div className="maker_container"><img key="829974684" src={nesquik_barcelona} className="maker_image" video="https://vimeo.com/829974684" onClick={this.imageClick} alt="829974684"/></div>
              <div className="maker_container"><img key="829976436" src={smart_malinche} className="maker_image" video="https://vimeo.com/829976436" onClick={this.imageClick} alt="829976436"/></div>
              <div className="maker_container"><img key="829976839" src={smart_cine} className="maker_image" video="https://vimeo.com/829976839" onClick={this.imageClick} alt="829976839"/></div>
              <div className="maker_container"><img key="829977416" src={smart_novela} className="maker_image" video="https://vimeo.com/829977416" onClick={this.imageClick} alt="829977416"/></div>
              <div className="maker_container"><img key="829977883" src={smart_organillero} className="maker_image" video="https://vimeo.com/829977883" onClick={this.imageClick} alt="829977883"/></div>
              <div className="maker_container"><img key="829968202" src={animalplanet_lost} className="maker_image" video="https://vimeo.com/829968202" onClick={this.imageClick} alt="829968202"/></div>
              <div className="maker_container"><img key="829971703" src={bimbo_especialidades} className="maker_image" video="https://vimeo.com/829971703" onClick={this.imageClick} alt="829971703"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;