
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'

import arrow from '../images/arrow-purple.svg'

import services_01 from '../images/services/services_01.jpg'
import services_02 from '../images/services/services_02.jpg'
import services_03 from '../images/services/services_03.jpg'
import services_04 from '../images/services/services_04.jpg'
import services_05 from '../images/services/services_05.jpg'
import services_06 from '../images/services/services_06.jpg'
import services_07 from '../images/services/services_07.jpg'
import services_08 from '../images/services/services_08.jpg'
import services_09 from '../images/services/services_09.jpg'
import services_10 from '../images/services/services_10.jpg'
import services_11 from '../images/services/services_11.jpg'
import services_12 from '../images/services/services_12.jpg'
import services_13 from '../images/services/services_13.jpg'
import services_14 from '../images/services/services_14.jpg'
import services_15 from '../images/services/services_15.jpg'
import services_16 from '../images/services/services_16.jpg'
import services_17 from '../images/services/services_17.jpg'
import services_18 from '../images/services/services_18.jpg'
import services_19 from '../images/services/services_19.jpg'
import services_20 from '../images/services/services_20.jpg'
import services_21 from '../images/services/services_21.jpg'
import services_22 from '../images/services/services_22.jpg'
import services_23 from '../images/services/services_23.jpg'
import services_24 from '../images/services/services_24.jpg'
import services_25 from '../images/services/services_25.jpg'
import services_26 from '../images/services/services_26.jpg'
import services_27 from '../images/services/services_27.jpg'
import services_28 from '../images/services/services_28.jpg'
import services_29 from '../images/services/services_29.jpg'
import services_30 from '../images/services/services_30.jpg'
import services_31 from '../images/services/services_31.jpg'
import services_32 from '../images/services/services_32.jpg'
import services_33 from '../images/services/services_33.jpg'
import services_34 from '../images/services/services_34.jpg'
import services_35 from '../images/services/services_35.jpg'
import services_36 from '../images/services/services_36.jpg'

import blob from '../images/blobs/blob_03.png'

function Services() {

  let timeOut = null
  let slider = null

  function prevReset() {

    slider.classList.remove("animation_left")
    slider.classList.remove("animation_right")


    var firstChild = slider.firstChild;
    var lastChild = slider.lastChild;
    slider.removeChild(lastChild)
    slider.insertBefore(lastChild, firstChild)

    window.clearTimeout(timeOut)

  }

  function nextReset() {

    slider.classList.remove("animation_left")
    slider.classList.remove("animation_right")

    var firstChild = slider.firstChild;
    slider.removeChild(firstChild)
    slider.appendChild(firstChild)

    window.clearTimeout(timeOut)

  }

  function prevSlider() {
    
    slider = document.getElementById("image_wrapper")
        
    slider.classList.remove("animation_right")
    slider.classList.add("animation_right")

    timeOut = setTimeout(prevReset, 1000)

  }

  function nextSlider() {
    
    slider = document.getElementById("image_wrapper")
        
    slider.classList.remove("animation_left")
    slider.classList.add("animation_left")

    timeOut = setTimeout(nextReset, 1000)

  }

  return (

    <div className="container services">

      <img src={blob} className="blob" alt="decoration" />

      <Header />
      
      <main className="main">

        <Banner title={<h2 className="title">production&ensp;<br/>services</h2>} />

        <div className="content">

          <p className="paragraph">
            MEXICO IS WELL KNOWN FOR ITS INTERNATIONAL AWARD WINNERS AT THE CINEMATOGRAPHY INDUSTRY. WE ARE CREATIVE PRODUCERS OF ALL KINDS OF CONTENTS.
          </p>

          <div className="services_image_slider">
            <div className="image_wrapper" id="image_wrapper">
              <img src={services_36} className="image" alt="services" />
              <img src={services_01} className="image" alt="services" />
              <img src={services_02} className="image" alt="services" />
              <img src={services_03} className="image" alt="services" />
              <img src={services_04} className="image" alt="services" />
              <img src={services_05} className="image" alt="services" />
              <img src={services_06} className="image" alt="services" />
              <img src={services_07} className="image" alt="services" />
              <img src={services_08} className="image" alt="services" />
              <img src={services_09} className="image" alt="services" />
              <img src={services_10} className="image" alt="services" />
              <img src={services_11} className="image" alt="services" />
              <img src={services_12} className="image" alt="services" />
              <img src={services_13} className="image" alt="services" />
              <img src={services_14} className="image" alt="services" />
              <img src={services_15} className="image" alt="services" />
              <img src={services_16} className="image" alt="services" />
              <img src={services_17} className="image" alt="services" />
              <img src={services_18} className="image" alt="services" />
              <img src={services_19} className="image" alt="services" />
              <img src={services_20} className="image" alt="services" />
              <img src={services_21} className="image" alt="services" />
              <img src={services_22} className="image" alt="services" />
              <img src={services_23} className="image" alt="services" />
              <img src={services_24} className="image" alt="services" />
              <img src={services_25} className="image" alt="services" />
              <img src={services_26} className="image" alt="services" />
              <img src={services_27} className="image" alt="services" />
              <img src={services_28} className="image" alt="services" />
              <img src={services_29} className="image" alt="services" />
              <img src={services_30} className="image" alt="services" />
              <img src={services_31} className="image" alt="services" />
              <img src={services_32} className="image" alt="services" />
              <img src={services_33} className="image" alt="services" />
              <img src={services_34} className="image" alt="services" />
              <img src={services_35} className="image" alt="services" />
            </div>
          </div>

          <div className='buttons'>
            <button className="button" onClick={prevSlider} >
              <img src={arrow} className="image" alt="arrow left" />
            </button>
            <button className="button" onClick={nextSlider} >
              <img src={arrow} className="image" alt="arrow right" />
            </button>
          </div>

          <p className="paragraph">
            THROUGHOUT OUR HISTORY AT THE PRODUCTION COMPANY, WE HAVE BUILT A FULL-SERVICE TEAM, WITH HIGHLY SPECIALIZED CREWS IN FILM PRODUCTION FOR ANY TYPE OF PROJECTS THAT SATISFIES THE MOST EXACTING STANDARDS OF OUR CLIENTS.
          </p>

          <p className="paragraph">
            AT THEREP MX, YOU CAN FIND BILINGUAL PEOPLE FOR ANY QUERY. FROM AMAZING HOLLYWOOD MOVIES TO TV SPOTS, TV SERIES, DIGITAL MEDIA, ART MOVIES, DOCUMENTARIES, AND MANY OTHER CONTENTS.
          </p>

          <p className="paragraph">
            MEXICO IS AN AMAZING DESTINATION FOR SHOOTING ALSO. WE HAVE ALL KINDS OF CLIMATES AND LOCATIONS WITH PERMISSION MANAGEMENT, MULTICULTURAL CASTING, AMAZING DOPS, ART DIRECTORS, MAKE UP AND COSTUME DESIGNERS, PROSTHETICS, FOREIGN TEAM ACCOMMODATION, TRANSPORTATION, TIME LAPSE, DRONES, STATE OF THE ART EQUIPMENT, ETC., AND EVERYTHING IN AFFORDABLE RATES.
          </p>

          <p className="paragraph">
            WE ARE PART OF A CONCIERGE VIP EXPERIENCE FOR CLIENTS AND CELEBRITIES IF DESIRED: GLAM TEAMS, CHEFS, MASSEUSES, CHAUFFERS WITH LUXURY VEHICLES, PERSONAL SECURITY SERVICE, TOURISTIC GUIDES, AND MANY OTHER SERVICES TO CREATE GREAT EXPERIENCES FULL OF GLAMOR.
          </p>

          <div className="separator"></div>

          <p className="paragraph bold">
            WE HAVE MANY THINGS TO OFFER SUCH AS:
          </p>

          <div className="services_wrapper">
            
            <div className="lists">

              <ul className="list">
                <li className="item">AMAZING LOCATIONS (BEACHES, DESERTS, VILLAGES, CITIES)</li>
                <li className="item">MULTICULTURAL CASTING</li>
                <li className="item">BEST PRODUCERS</li>
                <li className="item">AMAZING DOP'S</li>
              </ul>

              <ul className="list">

                <li className="item">ART DIRECTORS</li>
                <li className="item">LOGISTICS</li>
                <li className="item">CREW ACCOMMODATION</li>
                <li className="item">TRANSPORTATION</li>
                <li className="item">VIP CUSTOMER SERVICE</li>
                <li className="item">CONCIERGE & DRIVERS 24 HRS</li>
              </ul>

            </div>

            <div className="contact">

              <p className="write">IF YOU NEED A BUDGET, DON'T HESITATE TO WRITE US:</p>
              <a href="mailto:services@therep.mx" target="_blank" rel="noreferrer" className="email"><p className="email">SERVICES@THEREP.MX</p></a>

            </div>

          </div>

          <p className="paragraph bold">
            AND THE BEST PART, COMBINE YOUR JOB WITH LOTS OF FUN, TOURISM AND SHOPPING.
          </p>
          
        </div>
        
      </main>

      <Footer />
      
    </div>

  )

}

export default Services;
