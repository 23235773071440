
import { Link } from "react-router-dom"

import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'

import agustin_calderon from '../images/filmmakers/agustin_calderon.png'
import alex_reynaud from '../images/filmmakers/alex_reynaud.png'
import david_alcalde from '../images/filmmakers/david_alcalde.png'
import leo_ganora from '../images/filmmakers/leo_ganora.png'
import los_homies from '../images/filmmakers/los_homies.png'
import mario_zavala from '../images/filmmakers/mario_zavala.png'
import rafa_lara from '../images/filmmakers/rafa_lara.png'
import rodrigo_fiallega from '../images/filmmakers/rodrigo_fiallega.png'
import roby_chiralt from '../images/filmmakers/roby_chiralt.png'
import toledo_zavala from '../images/filmmakers/toledo_zavala.png'
import BRUNO from '../images/filmmakers/BRUNO.PNG'

import nadatalvez from '../images/filmmakers/nadatalvez.png'
import liberta from '../images/filmmakers/LibertaRomano.PNG'
import arturo_fincowsky from '../images/filmmakers/arturo_fincowsky.png'
import vanesa_manzano from '../images/filmmakers/vanesa_manzano.png'

import blob from '../images/blobs/blob_02.png'

function Filmmakers() {

  return (

    <div className="container filmmakers">

      <img src={blob} className="blob" alt="decoration" />

      <Header />
      
      <main className="main">

        <Banner title={<h2 className="title">the<br/>&ensp;filmmakers</h2>} />

        <div className="content">

          <p className="intro">OVER THE YEARS, WE HAVE ASSEMBLED A HIGH-END TEAM OF ADVERTISING VIDEOS, TV SERIES AND CINEMA DIRECTORS WHO FULLY UNDERSTAND THE INDUSTRY AND THE OBJECTIVES OF OUR CLIENTS. STORYTELLERS THAT ENCAPSULATE THE MOST PLEASANT EMOTIONS IN A SHORT SPACE OF TIME, THUS GENERATING IMPORTANT ROI FOR OUR CLIENTS.</p>

          <p className="subtitle">Click >> <a className="link" href="https://simian.me/aIn3_FILMMAKERS24">FILMMAKERS</a></p>

          {/*<ul className="list">
            
            <li className="item">

              <div className="section left">
                <img src={vanesa_manzano} className="image" alt="Vanesa Manzano" />
              </div>
              <div className="section right">
                <h4 className="name">Vanesa Manzano</h4>
                <p className="paragraph">#actingDirection #aesthetics #natural #humor #love</p>
                <Link to="/filmmakers/vanesa-manzano/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>
            
            <li className="item">

              <div className="section left">
                <img src={arturo_fincowsky} className="image" alt="Arturo Fincowsky" />
              </div>
              <div className="section right">
                <h4 className="name">Arturo Fincowsky</h4>
                <p className="paragraph">#storyteller #fashion #lifestyle #beauty #kids #dogs #tenderness</p>
                <Link to="/filmmakers/arturo-fincowsky/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>
            
            <li className="item">

              <div className="section left">
                <img src={rodrigo_fiallega} className="image" alt="Rodrigo Fiallega" />
              </div>
              <div className="section right">
                <h4 className="name">Rodrigo Fiallega</h4>
                <p className="paragraph">#acting #VFXartist #storytelling #acting #movies #tvspots</p>
                <Link to="/filmmakers/rodrigo-fiallega/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>
                        
            <li className="item">

              <div className="section left">
                <img src={nadatalvez} className="image" alt="Nadatalvez" />
              </div>
              <div className="section right">
                <h4 className="name">Nadatalvez</h4>
                <p className="paragraph">#Filmmaker #Cinematographer #StillPhotographer #Writer #FashionDesigner #AwardWinner #PhotoExhibitor</p>
                <Link to="/filmmakers/nadatalvez/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={david_alcalde} className="image" alt="David Alcalde" />
              </div>
              <div className="section right">
                <h4 className="name">David<br/>Alcalde</h4>
                <p className="paragraph">#TopInternational #Celebrities #OscarWinner #Storytelling #Narrative #Animation #TableTop #FantasyWorlds</p>
                <Link to="/filmmakers/david-alcalde/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={toledo_zavala} className="image" alt="Toledo & Zavala" />
              </div>
              <div className="section right">
                <h4 className="name">Toledo &<br/>Zavala</h4>
                <p className="paragraph">#TopInternational #Mexico+Spain #RemoteFilmmaking #Storytelling #SuperProductions</p>
                <Link to="/filmmakers/toledo-zavala/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>
            
            <li className="item">

              <div className="section left">
                <img src={liberta} className="image" alt="Liberta" />
              </div>
              <div className="section right">
                <h4 className="name">Liberta</h4>
                <p className="paragraph">#Fashion #LifeStyle #Models #Acting #Narrative #Writer</p>
                <Link to="/filmmakers/liberta/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>
            
            <li className="item">

              <div className="section left">
                <img src={BRUNO} className="image" alt="BrunoBengen" />
              </div>
              <div className="section right">
                <h4 className="name">Bruno Bengen</h4>
                <p className="paragraph">#productshot #foodporn #aesthetics #tabletop #acting #lifestyle</p>
                <Link to="/filmmakers/bruno-bengen/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={leo_ganora} className="image" alt="Leo Ganora" />
              </div>
              <div className="section right">
                <h4 className="name">Leo<br/>Ganora</h4>
                <p className="paragraph">#VisualFx #LiveAction+Animation #Narrative #International #CGI #Nuke #AwardWinner #TopModels #Metaverse</p>
                <Link to="/filmmakers/leo-ganora/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={mario_zavala} className="image" alt="Mario Zavala" />
              </div>
              <div className="section right">
                <h4 className="name">Mario<br/>Zavala</h4>
                <p className="paragraph">#Narrative #Emotions #Drone #Storytelling #Dog&Cats</p>
                <Link to="/filmmakers/mario-zavala/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={rafa_lara} className="image" alt="Rafa Lara" />
              </div>
              <div className="section right">
                <h4 className="name">Rafa<br/>Lara</h4>
                <p className="paragraph">#Movies #TvSeries #PrimeVideo #Netflix #ShowRunner #Fiction #Writer #Acting #TopActors #ActionCars</p>
                <Link to="/filmmakers/rafa-lara/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={agustin_calderon} className="image" alt="Agustín Calderón" />
              </div>
              <div className="section right">
                <h4 className="name">Agustín<br/>Calderón</h4>
                <p className="paragraph">#HighSpeed #Food #Delicious #TableTop #FoodStyle #MotionControl</p>
                <Link to="/filmmakers/agustin-calderon/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={los_homies} className="image" alt="Los Homies" />
              </div>
              <div className="section right">
                <h4 className="name">Los<br/>Homies</h4>
                <p className="paragraph">#LifeStyle #Fashion #Urban #Vanguard</p>
                <Link to="/filmmakers/los-homies/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={roby_chiralt} className="image" alt="Roby Chiralt" />
              </div>
              <div className="section right">
                <h4 className="name">Roby<br/>Chiralt</h4>
                <p className="paragraph">#Women #Babies #LifeStyle #Subtetly #Aesthetics #LiveAction+Animation</p>
                <Link to="/filmmakers/roby-chiralt/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

            <li className="item">

              <div className="section left">
                <img src={alex_reynaud} className="image" alt="Alex Z. Reynaud" />
              </div>
              <div className="section right">
                <h4 className="name">Alex Z.<br/>Reynaud</h4>
                <p className="paragraph">#Corporate #Cinematographer #Series #SocialMediaVideos #InternalCommunication</p>
                <Link to="/filmmakers/alex-reynaud/" className="link">
                  <p className="link_text">WORK</p>
                </Link>
              </div>

            </li>

          </ul>*/}

        </div>
        
      </main>

      <Footer />
      
    </div>

  )

}

export default Filmmakers;
