
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'

import contents_01 from '../images/contents/contents_01.png'
import contents_02 from '../images/contents/contents_02.png'
import contents_03 from '../images/contents/contents_03.png'
import contents_04 from '../images/contents/contents_04.png'
import contents_05 from '../images/contents/contents_05.png'
import contents_06 from '../images/contents/contents_06.png'
import contents_07 from '../images/contents/contents_07.jpg'

import blob_01 from '../images/blobs/blob_01.png'
import blob_06 from '../images/blobs/blob_06.png'

function Contents() {

  return (

    <div className="container contents">

      <img src={blob_01} className="blob_01" alt="decoration" />
      <img src={blob_06} className="blob_06" alt="decoration" />

      <Header />
      
      <main className="main">

        <Banner title={<h2 className="title">contents</h2>} />

        <div className="content">
          
          <p className="paragraph">
            STORYTELLING IS ONE OF THE MOST POWERFUL TOOLS TO GET CLOSER TO AUDIENCES THAT IS NO LONGER EXPOSED TO MASS MEDIA SUCH AS TV. THROUGH IT WE TELL STORIES OF INTEREST TO VARIOUS SECTORS WHERE A BRAND CAN BE INTRODUCED IN A “SILENT” WAY. WHEN USED IN MARKETING, THE BRANDED CONTENT, CAN EXPAND MUCH MORE AND SHOW THE BENEFITS OF THE PRODUCT IN FULL USE OF IT, AS WELL AS EXPOSE ACTUAL THEMES, PROVIDING RELEVANT INFORMATION TO NEW TARGETS EAGER FOR KNOWLEDGE.
          </p>
          
          <p className="paragraph">
            OUR TEAM OF CREATIVE PRODUCERS CAN DEVELOP MANY TYPES OF CONTENTS SUCH AS:
          </p>
          
          <div className="lists">
            
            <ul className="list">
              <li className="item">TV SPOTS</li>
              <li className="item">TV SERIES</li>
              <li className="item">WEB SERIES</li>
              <li className="item">MOVIES</li>
              <li className="item">TUTORIALS</li>
              <li className="item">CORPORATE VIDEOS</li>
              <li className="item">PODCASTS</li>
              <li className="item">PACKAGES OF CONTENTS FOR YOUR SOCIAL MEDIA PLATFORMS</li>
              <li className="item">EXPLANATORY ANIMATIONS</li>
            </ul>
            
            <ul className="list">
              <li className="item">RADIO SPOTS & CAPSULES</li>
              <li className="item">EXPO STANDS VIDEOS</li>
              <li className="item">MUSIC VIDEO CLIPS</li>
              <li className="item">EXPERIMENTAL VIDEOS</li>
              <li className="item">TIMELAPSE VIDEOS</li>
              <li className="item">DRONE CAPTURES</li>
              <li className="item">CONTENTS FOR RESTAURANT DISPLAYS, CONCERTS, SHOP WINDOWS, AND MORE</li>
              <li className="item">360-DEGREE DIGITAL WORLD FOR METAVERSE CONTENTS</li>
            </ul>

          </div>
          
          <div className="images">
            
            <div className="image_wrapper"><img src={contents_01} className="image circle" alt="contents_01" /></div>
            <div className="image_wrapper"><img src={contents_02} className="image" alt="contents_02" /></div>
            <div className="image_wrapper"><img src={contents_03} className="image circle small" alt="contents_03" /></div>
            <div className="image_wrapper"><img src={contents_04} className="image" alt="contents_04" /></div>
            <div className="image_wrapper"><img src={contents_05} className="image top" alt="contents_05" /></div>
            <div className="image_wrapper double"><img src={contents_06} className="image top" alt="contents_06" /></div>
            <div className="image_wrapper"><img src={contents_07} className="image circle top" alt="contents_07" /></div>
            

            <div className="images_text">

              <div className="images_center">
                <p className="meta">META</p>
                <p className="verse">VERSE</p>
                <div className="types_wrapper">
                  <p className="types">ALL<br/>TYPES<br/>OF<br/>CONTENTS</p>
                </div>
              </div>

            </div>

          </div>

        </div>
        
      </main>

      <Footer />
      
    </div>

  )

}

export default Contents;
