import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import adi_temet from "../../images/makers/nadatalvez/adi_temet.jpg"
import adidas from "../../images/makers/nadatalvez/adidas.jpg"
import crudo from "../../images/makers/nadatalvez/crudo.jpg"
import existo from "../../images/makers/nadatalvez/existo.jpg"
import grouper from "../../images/makers/nadatalvez/grouper.jpg"
import manifiesto from "../../images/makers/nadatalvez/manifiesto.jpg"
import puma from "../../images/makers/nadatalvez/puma.jpg"
import titae from "../../images/makers/nadatalvez/titae.jpg"
import various from "../../images/makers/nadatalvez/various.jpg"
import versed from "../../images/makers/nadatalvez/versed.jpg"
import middle from "../../images/makers/nadatalvez/middle.jpg"
import gepe from "../../images/makers/nadatalvez/gepe.jpg"
import explotala from "../../images/makers/nadatalvez/explotala.jpg"
import verruckte from "../../images/makers/nadatalvez/verruckte.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Nadatalvez</h2>

            <div className="description">

              <p className="text">Cinematographer. Filmmaker. Editor. Photographer. Writer. Designer. Cameraman for advertising, film, and editorial projects.</p>
              <p className="text">Born in Venezuela, he has lived in Spain and Chile, countries where he has acquired different linguistic and design understandings, now lives and works in Mexico City. He simultaneously directs in various formats and bodies of work, from video, film, photography, painting, and collage. Graduated as a filmmaker and with several years of experience working on artistic projects in addition to being a creative editor from the Creative Brothers School.</p>
              <p className="text">In 2019 he made the web series “Existo”, a series shot in 4 countries with 13 different artists from America.</p>
              <p className="text">He has participated in several collective exhibitions and has stood out in various film festivals 2011: Marbella Festival, 2019: FilmGate Miami, 2021: Bogoshort, and 2022: Venezuelan Film Festival, winning as best documentary feature film editor.</p>
              <p className="text">He designs and creates novelty clothing and accessories for his own fashion brand TEMET.</p>
              <p className="text">And as a photographer he has exhibited his work at the Fifth Annual Exposure Award 2015 Musee du Louvre France, as well as TheFinal SeeMe Exhibition at Art Basel Miami 2015.</p>

            </div>

            <div className="maker_wrapper">
              
              <div className="maker_container"><img key="774308549" src={adi_temet} className="maker_image" video="https://vimeo.com/774308549" onClick={this.imageClick} alt="774308549"/></div>
              <div className="maker_container"><img key="774308797" src={adidas} className="maker_image" video="https://vimeo.com/774308797" onClick={this.imageClick} alt="774308797"/></div>
              <div className="maker_container"><img key="774308893" src={crudo} className="maker_image" video="https://vimeo.com/774308893" onClick={this.imageClick} alt="774308893"/></div>
              <div className="maker_container"><img key="774309116" src={existo} className="maker_image" video="https://vimeo.com/774309116" onClick={this.imageClick} alt="774309116"/></div>
              <div className="maker_container"><img key="774309792" src={grouper} className="maker_image" video="https://vimeo.com/774309792" onClick={this.imageClick} alt="774309792"/></div>
              <div className="maker_container"><img key="774309873" src={manifiesto} className="maker_image" video="https://vimeo.com/774309873" onClick={this.imageClick} alt="774309873"/></div>
              <div className="maker_container"><img key="774310716" src={puma} className="maker_image" video="https://vimeo.com/774310716" onClick={this.imageClick} alt="774310716"/></div>
              <div className="maker_container"><img key="774310939" src={titae} className="maker_image" video="https://vimeo.com/774310939" onClick={this.imageClick} alt="774310939"/></div>
              <div className="maker_container"><img key="774310993" src={various} className="maker_image" video="https://vimeo.com/774310993" onClick={this.imageClick} alt="774310993"/></div>
              <div className="maker_container"><img key="774311896" src={versed} className="maker_image" video="https://vimeo.com/774311896" onClick={this.imageClick} alt="774311896"/></div>
              <div className="maker_container"><img key="774309925" src={middle} className="maker_image" video="https://vimeo.com/774309925" onClick={this.imageClick} alt="774309925"/></div>
              <div className="maker_container"><img key="774309431" src={gepe} className="maker_image" video="https://vimeo.com/774309431" onClick={this.imageClick} alt="774309431"/></div>
              <div className="maker_container"><img key="774309293" src={explotala} className="maker_image" video="https://vimeo.com/774309293" onClick={this.imageClick} alt="774309293"/></div>
              <div className="maker_container"><img key="774311319" src={verruckte} className="maker_image" video="https://vimeo.com/774311319" onClick={this.imageClick} alt="774311319"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;