import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import rizobacter from "../../images/makers/rizobacter.jpg"

import lomecan from "../../images/makers/lomecan.png"
import cfi from "../../images/makers/cfi.png"

import roby1 from "../../images/makers/roby1.jpg"
import lavirginia_cafe from "../../images/makers/lavirginia_cafe.jpg"
import vital_risas from "../../images/makers/vital_risas.jpg"
import te_lavirginia from "../../images/makers/te_lavirginia.jpg"
import nopucid_genio from "../../images/makers/nopucid_genio.jpg"
import lisopresol from "../../images/makers/lisopresol.jpg"
import vital_gatito from "../../images/makers/vital_gatito.jpg"
import evatest from "../../images/makers/evatest.jpg"
import nutrilon_kinder from "../../images/makers/nutrilon_kinder.jpg"
import dulcolax from "../../images/makers/dulcolax.jpg"
import nutrilon_achis from "../../images/makers/nutrilon_achis.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Roby Chiralt</h2>

            <div className="description">

              <p className="text">Born in Buenos Aires, Argentina, Roby began working in advertising production in 1999 and from then on, he has not stopped receiving national and international awards such as Golden Pencil, Eagle Award Mexico, Fiap, Sol de Bronce, Aspid Oro, among others.</p>
              <p className="text">He has been working for Argentina, Mexico, Chile, USA and Italy. More than two decades of experience are reflected at his work with a realistic style and sensitive look, achieving a unique and aesthetic piece in each project.</p>

            </div>

            <div className="maker_wrapper">
            
              <div className="maker_container"><img key="774351585" src={rizobacter} className="maker_image" video="https://vimeo.com/774351585" onClick={this.imageClick} alt="774351585"/></div>
              
              <div className="maker_container"><img key="718307853" src={lomecan} className="maker_image" video="https://vimeo.com/718307853" onClick={this.imageClick} alt="718307853"/></div>
              <div className="maker_container"><img key="718306499" src={cfi} className="maker_image" video="https://vimeo.com/718306499" onClick={this.imageClick} alt="718306499"/></div>
              
              <div className="maker_container"><img key="361185481" src={roby1} className="maker_image" video="https://vimeo.com/361185481" onClick={this.imageClick} alt="361185481"/></div>
              <div className="maker_container"><img key="269916306" src={lavirginia_cafe} className="maker_image" video="https://vimeo.com/269916306" onClick={this.imageClick} alt="269916306"/></div>
              <div className="maker_container"><img key="269915560" src={vital_risas} className="maker_image" video="https://vimeo.com/269915560" onClick={this.imageClick} alt="269915560"/></div>
              <div className="maker_container"><img key="269917060" src={te_lavirginia} className="maker_image" video="https://vimeo.com/269917060" onClick={this.imageClick} alt="269917060"/></div>
              <div className="maker_container"><img key="269916562" src={nopucid_genio} className="maker_image" video="https://vimeo.com/269916562" onClick={this.imageClick} alt="269916562"/></div>
              <div className="maker_container"><img key="269916423" src={lisopresol} className="maker_image" video="https://vimeo.com/269916423" onClick={this.imageClick} alt="269916423"/></div>
              <div className="maker_container"><img key="269915012" src={vital_gatito} className="maker_image" video="https://vimeo.com/269915012" onClick={this.imageClick} alt="269915012"/></div>
              <div className="maker_container"><img key="269916155" src={evatest} className="maker_image" video="https://vimeo.com/269916155" onClick={this.imageClick} alt="269916155"/></div>
              <div className="maker_container"><img key="269914801" src={nutrilon_kinder} className="maker_image" video="https://vimeo.com/269914801" onClick={this.imageClick} alt="269914801"/></div>
              <div className="maker_container"><img key="269915990" src={dulcolax} className="maker_image" video="https://vimeo.com/269915990" onClick={this.imageClick} alt="269915990"/></div>
              <div className="maker_container"><img key="269914185" src={nutrilon_achis} className="maker_image" video="https://vimeo.com/269914185" onClick={this.imageClick} alt="269914185"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;