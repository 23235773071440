import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import turin from "../../images/makers/manzano/turin.avif"
import google from "../../images/makers/manzano/google.avif"
import nissan from "../../images/makers/manzano/nissan.avif"
import ximena from "../../images/makers/manzano/ximena.avif"
import pancho from "../../images/makers/manzano/pancho.avif"
import walmart from "../../images/makers/manzano/walmart.avif"
import vick from "../../images/makers/manzano/vick.avif"
import saladitas from "../../images/makers/manzano/saladitas.avif"
import monange from "../../images/makers/manzano/monange.avif"
import nave from "../../images/makers/manzano/nave.avif"
import camioneta from "../../images/makers/manzano/camioneta.avif"
import doritos from "../../images/makers/manzano/doritos.avif"
import champ from "../../images/makers/manzano/champ.avif"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Vanesa Manzano</h2>

            <div className="description">

              <p className="text">Nacida en España, Vanesa Manzano cursó la Licenciatura de Comunicación Audiovisual en la Universidad Europea de Madrid. Al finalizar, se fue a Cuba, en donde hizo una especialización en Guión en la EICTV; y posteriormente se marchó a Nueva York a estudiar Dirección de Cine en la New York Film Academy.</p>
              <p className="text">Sus primeros pasos en el medio los dio en Madrid, mientras seguía formándose con escapadas a Londres para estudiar Dirección de Documentales en la London Film Academy. De 2011 a la actualidad continúa su desarrollo profesional en México, trabajando para el mercado nacional, LATAM; EEUU, Canadá y España.</p>
              <p className="text">Sus características principales son un potente ojo estético y la dirección de actores de corte natural, surgida de la situación y complejidades del personaje.</p>

            </div>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="846756927" src={turin} className="maker_image" video="https://vimeo.com/846756927" onClick={this.imageClick} alt="846756927"/></div>
              <div className="maker_container"><img key="846756373" src={google} className="maker_image" video="https://vimeo.com/846756373" onClick={this.imageClick} alt="846756373"/></div>
              <div className="maker_container"><img key="846756042" src={nissan} className="maker_image" video="https://vimeo.com/846756042" onClick={this.imageClick} alt="846756042"/></div>
              <div className="maker_container"><img key="846755682" src={ximena} className="maker_image" video="https://vimeo.com/846755682" onClick={this.imageClick} alt="846755682"/></div>
              <div className="maker_container"><img key="846755537" src={pancho} className="maker_image" video="https://vimeo.com/846755537" onClick={this.imageClick} alt="846755537"/></div>
              <div className="maker_container"><img key="846449484" src={walmart} className="maker_image" video="https://vimeo.com/846449484" onClick={this.imageClick} alt="846449484"/></div>
              <div className="maker_container"><img key="846449317" src={vick} className="maker_image" video="https://vimeo.com/846449317" onClick={this.imageClick} alt="846449317"/></div>
              <div className="maker_container"><img key="846449250" src={saladitas} className="maker_image" video="https://vimeo.com/846449250" onClick={this.imageClick} alt="846449250"/></div>
              <div className="maker_container"><img key="846449147" src={monange} className="maker_image" video="https://vimeo.com/846449147" onClick={this.imageClick} alt="846449147"/></div>
              <div className="maker_container"><img key="846449101" src={nave} className="maker_image" video="https://vimeo.com/846449101" onClick={this.imageClick} alt="846449101"/></div>
              <div className="maker_container"><img key="846448938" src={camioneta} className="maker_image" video="https://vimeo.com/846448938" onClick={this.imageClick} alt="846448938"/></div>
              <div className="maker_container"><img key="846448855" src={doritos} className="maker_image" video="https://vimeo.com/846448855" onClick={this.imageClick} alt="846448855"/></div>
              <div className="maker_container"><img key="846448767" src={champ} className="maker_image" video="https://vimeo.com/846448767" onClick={this.imageClick} alt="846448767"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;