
function Banner({ title, subtitle=null }) {

  return (
      <header className="banner">

        <div className="banner_container">

          <div className="title_wrapper">{title}</div>

          <div className="bottom">
            <div className="line"></div>
            <p className="subtitle">{subtitle}</p>
          </div>

        </div>

      </header>
  );
  
}

export default Banner;
