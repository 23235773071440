import facebook from '../images/social/facebook.svg'
import instagram from '../images/social/instagram.svg'
import tiktok from '../images/social/tiktok.svg'

function Footer() {

  return (

      <header className="footer">

        <div className="section left">

          <div className="content">

            <a href="https://wa.me/5534535523/" target="_blank" rel="noopener noreferrer" className="title conversation">
              <p className="title conversation">
                START A CONVERSATION
              </p>
            </a>

            <p className="address">Alejandro Dumas 16, Polanco</p>
            <p className="address">Miguel Hidalgo</p>
            <p className="address">Zip Code 11550</p>
            <p className="address">CDMX, México</p>

          </div>

        </div>

        <div className="section right">

          <div className="content">

            <p className="title">Follow Us</p>

            <a href="mailto:produccion@therep.mx" target="_blank" rel="noreferrer" className="email">produccion@therep.mx</a>

            <ul className="social">

              <li className="item">
                <a className="link" href="https://www.facebook.com/therepmx" target="_blank" rel="noopener noreferrer">
                  {/*<p className="text facebook">Facebook</p>*/}
                  <img src={facebook} className="logo" alt="facebook" />
                </a>
              </li>
              <li className="item">
                <a className="link" href="https://www.instagram.com/laproducer_therepmx" target="_blank" rel="noopener noreferrer">
                  {/*<p className="text instagram">Instagram</p>*/}
                  <img src={instagram} className="logo" alt="instagram" />
                </a>
              </li>
              <li className="item">
                <a className="link" href="https://www.tiktok.com/@therepmx" target="_blank" rel="noopener noreferrer">
                  {/*<p className="text tiktok">TikTok</p>*/}
                  <img src={tiktok} className="logo" alt="tiktok" />
                </a>
              </li>

            </ul>

          </div>

        </div>

      </header>
  )
  
}

export default Footer
