
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'

import laproducer from '../images/laproducer.png'
import blob from '../images/blobs/blob_07.png'

function Producer() {

  return (

    <div className="container laproducer">

      <img src={blob} className="blob" alt="decoration" />

      <Header />
      
      <main className="main">

        <Banner title={<h2 className="title">laproducer</h2>} />

        <div className="content">

          <div className="wrapper">
            
            <p className="paragraph">
              THROUGHOUT 17 YEARS AS FOUNDER AND EXECUTIVE PRODUCER OF THEREP MX, MARIANA HAS BECOME A SENIOR AUDIOVISUAL PRODUCER AND POST PRODUCER LEADING EXTENSIVE TEAMS AND REPRESENTING FILM DIRECTORS OF ALL SIZES, BOTH NATIONAL AND INTERNATIONAL.
            </p>
            
            <p className="paragraph">
              SHE HAS SHOT IN ARGENTINA, SPAIN, FRANCE, USA, NETHERLANDS, COSTA RICA, CANADA AND OF COURSE IN MEXICO. WORKED FOR CHINESE COMPANIES AS TENCENT AND TIKTOK IN VARIOUS JOINT VENTURES.
            </p>
            
            <p className="paragraph">
              SHE ALSO HAS BEEN SHOWRUNNER OF CONTENTS AND MODEL FOR THE MEXICAN CAMPAIGNS OF COSMECEUTICAL AND BEAUTY COREAN INDUSTRY.
            </p>
            
            <p className="paragraph">
              WORKING FOR THE MAJORITY AD AGENCIES ON A DAY-TO-DAY BASIS, SHE HAS PRODUCED HUNDREDS OF TV SPOTS FOR COUNTLESS NATIONAL AND TRANSNATIONAL BRANDS. SHE IS ALWAYS IN CONSTANT SEARCH TO IMPROVE, TREAT THE TEAM LIKE FAMILY AND SO, ALL TOGETHER CAN SATISFY THE NEEDS OF THE PROJECTS WITH A COMMON AIM.
            </p>

          </div>

          <img src={laproducer} className="image" alt="LaProducer" />
          
        </div>
        
      </main>

      <Footer />
      
    </div>

  )

}

export default Producer;
