import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import liberta_reel from "../../images/makers/liberta/liberta_reel.jpg"
import american_eagle from "../../images/makers/liberta/american_eagle.jpg"
import american_02 from "../../images/makers/liberta/american_02.jpg"
import lg from "../../images/makers/liberta/lg.jpg"
import era from "../../images/makers/liberta/era.jpg"
import gacel from "../../images/makers/liberta/gacel.jpg"
import marineros from "../../images/makers/liberta/marineros.jpg"
import transicion from "../../images/makers/liberta/transicion.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="container maker">

          <Header />

          <div className="main">

            <h2 className="title">Liberta</h2>

            <div className="description">

              <p className="text">South American film director, currently based in Mexico City.</p>
              <p className="text">iberta Romano is an interdisciplinary artist who combines film and photography in narrative projects that cross the barriers of genre and format.</p>
              <p className="text">is personal work, the evolutionary engine that drives him to discover new ways of generating stories, connects science fiction and reality in dimensions created by symbols hidden in darkness, light, humanity, love, irony, and magic.</p>

            </div>

            <div className="maker_wrapper">
              
              <div className="maker_container"><img key="776768577" src={liberta_reel} className="maker_image" video="https://vimeo.com/776768577" onClick={this.imageClick} alt="776768577"/></div>
              <div className="maker_container"><img key="776768939" src={american_eagle} className="maker_image" video="https://vimeo.com/776768939" onClick={this.imageClick} alt="776768939"/></div>
              <div className="maker_container"><img key="776769093" src={american_02} className="maker_image" video="https://vimeo.com/776769093" onClick={this.imageClick} alt="776769093"/></div>
              <div className="maker_container"><img key="776769190" src={lg} className="maker_image" video="https://vimeo.com/776769190" onClick={this.imageClick} alt="776769190"/></div>
              <div className="maker_container"><img key="776769293" src={era} className="maker_image" video="https://vimeo.com/776769293" onClick={this.imageClick} alt="776769293"/></div>
              <div className="maker_container"><img key="776769538" src={gacel} className="maker_image" video="https://vimeo.com/776769538" onClick={this.imageClick} alt="776769538"/></div>
              <div className="maker_container"><img key="776769931" src={marineros} className="maker_image" video="https://vimeo.com/776769931" onClick={this.imageClick} alt="776769931"/></div>
              <div className="maker_container"><img key="776770082" src={transicion} className="maker_image" video="https://vimeo.com/776770082" onClick={this.imageClick} alt="776770082"/></div>

            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;